import React, {useEffect} from "react"
import Home from './home';
import {connect} from "react-redux";

const IndexPage = ({showCookiesDialog, hideCookiesDialog}) => {
    if (typeof window !== 'undefined' && window.document) {
        const gaProperty = 'G-6PD37M8584';
        const disableGaStr = 'ga-disable-' + gaProperty;
        if (window.document.cookie.indexOf(disableGaStr + '=true') > -1) {
            window[disableGaStr] = true;
        }
    }

    useEffect(() => {
        const userCookies = localStorage.getItem('userCookies');

        if (!userCookies) {
            showCookiesDialog();
        }
    }, [showCookiesDialog])

    return (
        <Home/>
    )
}

const mapStateToProps = state => {
    return {
        cookiesDialogVisible: state.cookiesDialogVisible
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showCookiesDialog: () => dispatch({type: `SHOW_DIALOG_COOKIES`}),
        hideCookiesDialog: () => dispatch({type: `HIDE_DIALOG_COOKIES`}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexPage);


